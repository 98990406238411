const location_url = `${typeof window !== "undefined" && location.href}`;
const defaultPreviewImage = `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/Preview-seed.webp`;
export const defaultSeoMeta = {
  title: "SEED - 1st NFT Photography Marketplace Trade, Buy & Sell Art",
  canonical: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}`,
  og_image: defaultPreviewImage,
  og_title: "Seed.photo",
  og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}`,
  og_image_type: "image/jpeg",
  og_image_width: "800",
  description:
    "Seed.photo - First marketplace platform for crypto collectibles and non-fungible tokens NFTs photography. Explore, buy, sell, trade exclusive digital art items",
  twitter_image: defaultPreviewImage,
  twitter_image_alt: "Seed",
  twitter_title: "Seed.photo",
  twitter_image_width: "800",
  preLoadImage: defaultPreviewImage,
  robotIndex: true,
};

export const marketPlaceSeoMeta = {
  title: "NFT Marketplace: A Community Sharing Passion for Artworks",
  canonical: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/marketplace`,
  og_image: defaultPreviewImage,
  og_title: "Seed.Photo marketplace",
  og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/marketplace`,
  og_image_type: "image/jpeg",
  og_image_width: "800",
  description:
    "Discover our multifaceted NFT marketplace ecosystem Create, buy, sell, trade and engage with SEED.Photo Artworks community and celebrate the art of photography",
  twitter_image: defaultPreviewImage,
  twitter_image_alt: "Seed",
  twitter_title: "Seed.Photo marketplace",
  twitter_image_width: "800",
  preLoadImage: defaultPreviewImage,
  robotIndex: true,
};

export const exploreSeoMeta = {
  title: "Explore our NFT Collectible Photography Gallery",
  canonical: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/nfts`,
  og_image: defaultPreviewImage,
  og_title: "Seed.photo Explore",
  og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/nfts`,
  og_image_type: "image/jpeg",
  og_image_width: "800",
  description:
    "Explore our uniqe NFT photography galley of collectible moments produced by digital artists & creators worldwide sold for a fixed or auction price | Seed.photo",
  twitter_image: defaultPreviewImage,
  twitter_image_alt: "Seed.Photo",
  twitter_title: "Seed.Photo",
  twitter_image_width: "800",
  preLoadImage: defaultPreviewImage,
  robotIndex: true,
};

export const inspireSeoMeta = {
  title: "Inspire NFTs | Buy & Sell | Seed.photo Marketplace",
  canonical: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/inspire`,
  og_image: defaultPreviewImage,
  og_title: "Seed.photo Inspire",
  og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/inspire`,
  og_image_type: "image/jpeg",
  og_image_width: "800",
  description:
    "Explore our uniqe NFT photography galley of collectible moments produced by digital artists & creators worldwide sold for a fixed or auction price | Seed.photo",
  twitter_image: defaultPreviewImage,
  twitter_image_alt: "Seed.Photo",
  twitter_title: "Seed.Photo",
  twitter_image_width: "800",
  preLoadImage: defaultPreviewImage,
  robotIndex: true,
};

export const guideSeoMeta = {
  title: "Comprehensive NFT Marketplace Guidelines and Tutorials",
  canonical: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/tutorials`,
  og_image: defaultPreviewImage,
  og_title: "Seed.photo Tutorials",
  og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/tutorials`,
  og_image_type: "image/jpeg",
  og_image_width: "800",
  description:
    "Master NFT creation and trading with our comprehensive guidelines and tutorials. Explore the NFT world like a pro!",
  twitter_image: defaultPreviewImage,
  twitter_image_alt: "Seed.Photo",
  twitter_title: "Seed.Photo",
  twitter_image_width: "800",
  preLoadImage: defaultPreviewImage,
  robotIndex: true,
};

export const topArtistsSeoMeta = {
  title: "Top Photographers on the Premier NFT Marketplace",
  canonical: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/top-artists`,
  og_image: defaultPreviewImage,
  og_title: "Seed.photo Top Artists",
  og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/top-artists`,
  og_image_type: "image/jpeg",
  og_image_width: "800",
  description:
    "Explore the stunning world of NFT photography with our top photographers on the premier NFT marketplace.",
  twitter_image: defaultPreviewImage,
  twitter_image_alt: "Seed.Photo",
  twitter_title: "Seed.Photo",
  twitter_image_width: "800",
  preLoadImage: defaultPreviewImage,
  robotIndex: true,
};

export const topCollectorsSeoMeta = {
  title: "Discover the Elite: Top 10 NFT Art Collectors",
  canonical: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/top-collectors`,
  og_image: defaultPreviewImage,
  og_title: "Seed.photo Top Collectors",
  og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/top-collectors`,
  og_image_type: "image/jpeg",
  og_image_width: "800",
  description:
    "Dive into the world of elite NFT art collectors. Explore the top 10 collectors who shape the NFT art landscape.",
  twitter_image: defaultPreviewImage,
  twitter_image_alt: "Seed",
  twitter_title: "Seed.photo",
  twitter_image_width: "800",
  preLoadImage: defaultPreviewImage,
  robotIndex: true,
};

export const topPhotographersSeoMeta = {
  title: "Capturing Brilliance: Top 10 NFT Photographers",
  canonical: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/top-photographers`,
  og_image: defaultPreviewImage,
  og_title: "Seed.photo Top Photographers",
  og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/top-photographers`,
  og_image_type: "image/jpeg",
  og_image_width: "800",
  description:
    "Discover the brilliance of NFT photography with the top 10 photographers in the industry. Unveil stunning visual art.",
  twitter_image: defaultPreviewImage,
  twitter_image_alt: "Seed",
  twitter_title: "Seed.photo",
  twitter_image_width: "800",
  preLoadImage: defaultPreviewImage,
  robotIndex: true,
};

export const topCuratorsSeoMeta = {
  title: "Top 5 NFT Marketplace Curation Experts",
  canonical: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/top-curators`,
  og_image: defaultPreviewImage,
  og_title: "Seed.photo Top Curators",
  og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/top-curators`,
  og_image_type: "image/jpeg",
  og_image_width: "800",
  description:
    "Meet the industry's top 5 NFT marketplace curation experts. Explore their insights and discover remarkable digital art",
  twitter_image: defaultPreviewImage,
  twitter_image_alt: "Seed",
  twitter_title: "Seed.photo",
  twitter_image_width: "800",
  preLoadImage: defaultPreviewImage,
  robotIndex: true,
};

export const communityGuidelinesSeoMeta = {
  title: "SEED NFT Marketplace Community Guidelines",
  canonical: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/community-guidelines`,
  og_image: defaultPreviewImage,
  og_title: "SEED Community Guidelines",
  og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/community-guidelines`,
  og_image_type: "image/jpeg",
  og_image_width: "800",
  description:
    "Seed.Photo team is committed to maintain an environment that empowers artists and collectors to connect, collaborate, and participate in the digital art economy",
  twitter_image: defaultPreviewImage,
  twitter_image_alt: "Seed",
  twitter_title: "SEED Community Guidelines",
  twitter_image_width: "800",
  preLoadImage: defaultPreviewImage,
  robotIndex: true,
};

export const twitterFarmSeoMeta = {
  title: "Earn Rewards with Seed NFT Photo's Twitter Farm Tool",
  canonical: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/twitter-farm`,
  og_image: defaultPreviewImage,
  og_title: "SEED.Photo Twitter Farm",
  og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/twitter-farm`,
  og_image_type: "image/jpeg",
  og_image_width: "800",
  description:
    "Use Seed Photo Twitter Farm to grow your audience and earn rewards. Engage with the community and unlock exciting benefits while building your Twitter presence",
  twitter_image: defaultPreviewImage,
  twitter_image_alt: "Seed",
  twitter_title: "SEED.Photo Twitter Farm",
  twitter_image_width: "800",
  preLoadImage: defaultPreviewImage,
  robotIndex: true,
};

export const exclusiveCollectionsSeoMeta = {
  title: "Exclusive NFT Collections by photographer for photographers",
  canonical: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/collections/exclusive`,
  og_image: defaultPreviewImage,
  og_title: "SEED.Photo Exclusive Collections",
  og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/collections/exclusive`,
  og_image_type: "image/jpeg",
  og_image_width: "800",
  description:
    "Explore exclusive NFT collections by photographers, for photographers. Discover and mint unique digital art in our marketplace",
  twitter_image: defaultPreviewImage,
  twitter_image_alt: "Seed.Photo",
  twitter_title: "SEED.Photo Exclusive Collections",
  twitter_image_width: "800",
  preLoadImage: defaultPreviewImage,
  robotIndex: true,
};

export const categorySeoMeta = {
  title: "NFT Photography & Digital Art Marketplace - Categories",
  canonical: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/categories`,
  og_image: defaultPreviewImage,
  og_title: "NFT Photography & Digital Art Marketplace - Categories",
  og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/categories`,
  og_image_type: "image/jpeg",
  og_image_width: "800",
  description:
    "Explore diverse NFT photography categories on our marketplace, from Architecture and Fashion to Sports. Discover unique digital art in every style and theme!",
  twitter_image: defaultPreviewImage,
  twitter_image_alt: "Seed.Photo",
  twitter_title: "NFT Photography & Digital Art Marketplace - Categories",
  twitter_image_width: "800",
  preLoadImage: defaultPreviewImage,
  robotIndex: true,
};

export function generateSeoObject(
  title,
  image = defaultPreviewImage,
  twitterImage = defaultPreviewImage,
  preLoadImage = defaultPreviewImage,
  description = defaultSeoMeta.description,
  endText = true,
  endUrl = "",
  noCanonical = false
) {
  let descriptionProfile = "";
  if (description.length <= 170) {
    descriptionProfile = description;
  } else if (description.length > 170) {
    let truncated = description !== null ? description.substring(0, 170) : "";
    const lastSpaceIndex = truncated.lastIndexOf(" ");

    if (lastSpaceIndex !== -1) {
      truncated = truncated.substring(0, lastSpaceIndex);
      descriptionProfile = truncated.trim();
    }
  }
  const seoObject = {
    title: endText ? `${title}| Seed.Photo` : title,
    canonical: !noCanonical
      ? `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/${endUrl}`
      : `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}`,
    og_image: image,
    og_title: endText ? `${title}| Seed.Photo` : title,
    og_url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}/${endUrl}`,
    og_image_type: "image/jpeg",
    og_image_width: "800",
    description: description,
    twitter_image: twitterImage ? twitterImage : image,
    twitter_image_alt: "Seed",
    twitter_title: endText ? `${title}| Seed.Photo` : title,
    twitter_image_width: "800",
    preLoadImage: preLoadImage ? preLoadImage : image,
    robotIndex: !noCanonical,
  };

  return seoObject;
}

export function nftDetailMetaDescriptionGenrator(
  nftTitle,
  artist,
  nftCategory,
  nftStory
) {
  const fullDescription = `${nftTitle} by artist ${artist} on Seed.photo NFT marketplace, Unique ${nftCategory} photography. ${nftStory}`;

  if (fullDescription.length <= 170) {
    return fullDescription;
  }
  let truncated = fullDescription.substring(0, 170);
  const lastSpaceIndex = truncated.lastIndexOf(" ");

  if (lastSpaceIndex !== -1) {
    truncated = truncated.substring(0, lastSpaceIndex);
  }
  return truncated.trim();
}

export function collectionMetaDescriptionGenerator(
  collectionTitle,
  artist,
  collectionDescription
) {
  const fullDescription = `${collectionTitle} by artist ${artist} on Seed.photo NFT marketplace. ${collectionDescription}`;

  if (fullDescription.length <= 170) {
    return fullDescription;
  }

  let truncated = fullDescription.substring(0, 170);
  const lastSpaceIndex = truncated.lastIndexOf(" ");

  if (lastSpaceIndex !== -1) {
    truncated = truncated.substring(0, lastSpaceIndex);
  }
  return truncated.trim();
}
