import React from "react";
import burstPucker from "@/assets/images/Burst-pucker.svg";
import Image from "next/image";

function BurstPuckerImageTitles({ width = 30, height = 30 }) {
  return (
    <Image src={burstPucker} alt="Burst-pucker" width={width} height={height} />
  );
}

export default React.memo(BurstPuckerImageTitles);
