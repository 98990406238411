import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import Image from "next/image";
import Link from "next/link";

import CommentActive from "@/assets/images/home/twitterCommunity/CommentActive.svg";
import Comment from "@/assets/images/home/twitterCommunity/message-text.svg";

import Follow from "@/assets/images/home/twitterCommunity/Follow.svg";
import FollowActive from "@/assets/images/home/twitterCommunity/FollowActive.svg";

import like from "@/assets/images/home/twitterCommunity/heart.svg";
import likeActive from "@/assets/images/home/twitterCommunity/likeActive.svg";

import Repost from "@/assets/images/home/twitterCommunity/Repost.svg";
import RepostActive from "@/assets/images/home/twitterCommunity/RepostActive.svg";

import Retweet from "@/assets/images/home/twitterCommunity/repeat.svg";
import RetweetActive from "@/assets/images/home/twitterCommunity/RetweetActive.svg";

import videoDark from "@/assets/images/home/twitterCommunity/video-dark.webp";
import videoLight from "@/assets/images/home/twitterCommunity/video-light.webp";
import { selectGetMyPointsData } from "@/config/helpers/createReduxSelectors";
import { getTwitterSetting } from "@/store/actions";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const MinimalConnectWallet = dynamic(
  () => import("@/config/helpers/minimalConnectWallet"),
  {
    ssr: false,
  }
);

function TwitterCommunitySection({ title, link, isNavigateDashboard = false }) {
  const dispatch = useDispatch();
  const [section, setSection] = useState(1);

  const { TwitterSetting } = useSelector(selectGetMyPointsData);

  useEffect(() => {
    dispatch(getTwitterSetting());
  }, []);

  return (
    <>
      <div className="center">
        <div
          className={
            title === "Unlocking Rewards Through Twitter Engagement"
              ? "tw_farm__engagement__head"
              : "home__nftMerketplace_head"
          }
        >
          <div
            className={` burst   ${
              title === "Unlocking Rewards Through Twitter Engagement"
                ? "tw_farm__engagement_title"
                : "home__nftMerketplace__title"
            }`}
          >
            <div
              className={
                title === "Unlocking Rewards Through Twitter Engagement"
                  ? "mobile-hide"
                  : ""
              }
            >
              <BurstPuckerImageTitles />{" "}
            </div>
            {title}
          </div>

          {!isNavigateDashboard ? (
            <div className="home__nftMerketplace__btnExploreforPC">
              <Link className="button-stroke" href={`${link}`}>
                Explore More
                <i className="icon-chevron_up"></i>
              </Link>
            </div>
          ) : null}
        </div>

        <div className="home__twitterCommunity">
          <div className="home__twitterCommunity__section">
            <div className="home__twitterCommunity__section1">
              <div className="home__twitterCommunity__title">
                SEEDX Boosters: Elevate Your Engagement, Earn Rewards
              </div>
              <div className="home__twitterCommunity__section1-button">
                <div
                  className={`${
                    section === 1
                      ? ""
                      : // ? "home__twitterCommunity__button-active"
                        "home__twitterCommunity__button-disable"
                  }`}
                  onClick={() => setSection(1)}
                >
                  <Image src={section === 1 ? likeActive : like} alt="heart" />
                </div>
                <div
                  className={`${
                    section === 2
                      ? ""
                      : // ? "home__twitterCommunity__button-active"
                        "home__twitterCommunity__button-disable"
                  }`}
                  onClick={() => setSection(2)}
                >
                  <Image
                    src={section === 2 ? CommentActive : Comment}
                    alt="message"
                  />
                </div>
                <div
                  className={`${
                    section === 3
                      ? ""
                      : "home__twitterCommunity__button-disable"
                  }`}
                  onClick={() => setSection(3)}
                >
                  <Image
                    src={section === 3 ? RepostActive : Repost}
                    alt="repeat"
                  />
                </div>
                <div
                  className={`${
                    section === 4
                      ? ""
                      : // ? "home__twitterCommunity__button-active"
                        "home__twitterCommunity__button-disable"
                  }`}
                  onClick={() => setSection(4)}
                >
                  <Image
                    src={section === 4 ? RetweetActive : Retweet}
                    alt="repeat"
                  />
                </div>
                <div
                  className={`${
                    section === 5
                      ? ""
                      : // ? "home__twitterCommunity__button-active"
                        "home__twitterCommunity__button-disable"
                  }`}
                  onClick={() => setSection(5)}
                >
                  <Image
                    src={section === 5 ? FollowActive : Follow}
                    alt="repeat"
                  />
                </div>
              </div>
              <div className="home__twitterCommunity__section-like">
                <div className="home__twitterCommunity__text">
                  {section === 1
                    ? "Like for Love:"
                    : section === 2
                    ? "Reply Cultivator:"
                    : section === 3
                    ? "Repost Rendezvous:"
                    : section === 4
                    ? "Hashtag Harvesting:"
                    : "New Users Referral:"}
                </div>
                <div className="home__twitterCommunity__text3">
                  {section === 1
                    ? `Every like on SEED.Photo spreads positivity and appreciation, earning you 
                       SEEDX tokens while supporting fellow photographers. 
                       Share the love and watch your rewards grow!`
                    : section === 2
                    ? `Engage with thoughtful comments that spark conversations and connections. 
                       Each comment not only enriches the SEED.Photo community, but also earns you 
                       SEEDX rewards for your valuable contributions.`
                    : section === 3
                    ? `Showcase your favorite SEED.Photo content by reposting it to your profile. 
                       By spreading the beauty of photography, you not only inspire others but also earn 
                       SEEDX rewards for your contribution to the community.`
                    : section === 4
                    ? `Boost your SEEDX earnings by using #SEEDPhoto on Twitter. Share your photography, 
                       connect with the community, and earn SEEDX tokens for each hashtagged post. Share your 
                       passion and grow your rewards!`
                    : `Invite your friends to join SEED.Photo and engage on Twitter! Share your unique 
                       referral link. When your friends sign up and participate in Twitter activities such 
                       as posting, commenting, or retweeting, you'll both earn SEEDX tokens. 
                       Spread the word and let's grow our community together!`}
                </div>
              </div>
              <div className="home__twitterCommunity__text2">
                {/* + */}
                {TwitterSetting
                  ? section === 1
                    ? TwitterSetting?.LikeScore
                    : section === 2
                    ? TwitterSetting?.ReplyScore
                    : section === 3
                    ? TwitterSetting?.RepostScore
                    : section === 4
                    ? TwitterSetting?.HashtagScore
                    : TwitterSetting?.ReferralInviteUserScore
                  : "-"}
                &nbsp;
                <div className="home__twitterCommunity__text2_colorful">
                  SEEDX
                </div>
              </div>
            </div>
          </div>
          <div className="home__twitterCommunity__section">
            <div className="home__twitterCommunity__section1">
              <div className="home__twitterCommunity__title">
                TweetCraft: Mastering Twitter for SEED.Photo Enthusiasts
              </div>
              <div className="home__twitterCommunity__text3">
                Join us on a journey through the Twitterverse as we unlock the
                secrets to maximizing your presence and engagement within the
                SEED.Photo community.
              </div>
            </div>
            <div className="home__twitterCommunity__images">
              <div className="home__hero__dark">
                <Image
                  src={videoDark}
                  alt="section-hero"
                  className="home__hero__img"
                />
              </div>
              <div className="home__hero__light">
                <Image
                  src={videoLight}
                  alt="section-hero"
                  className="home__hero__img"
                />
              </div>
            </div>
          </div>
        </div>

        {!isNavigateDashboard ? (
          <div className="home__nftMerketplace">
            <Link
              className="button-stroke home__twitterCommunity__tablet"
              href={`${link}`}
            >
              Explore More
              <i className="icon-chevron_up"></i>
            </Link>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default TwitterCommunitySection;
